document.addEventListener('DOMContentLoaded', function () {
  var openSocials = function openSocials() {
    var btn = $("#product-social-open"),
        socialBtns = $('.product-social-btns .social-btns');
    btn.on("click", function () {
      socialBtns.slideToggle(200);
    });
  };

  openSocials();
});
import '../styles/general.scss';
import '../styles/common/icons.scss';
import '../styles/common/inputs.scss';
import '../styles/common/validation.scss';
import '../styles/partials/breads.scss';
import '../styles/partials/gallery.scss';
import '../styles/partials/header.scss';
import '../styles/partials/menu-dropdown.scss';
import '../styles/partials/price.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/common/buttons.scss';
import '../styles/common/custom-input.scss';
import '../styles/partials/menu-general.scss';
import '../styles/partials/menu-header.scss';
import '../styles/views/home.scss';
import '../styles/views/product.scss';
import '../scripts/_partials/product-view/productView.module.js';
import '../styles/common/block.scss';
import '../styles/common/forms.scss';
import '../styles/common/headers.scss';
import '../styles/common/links.scss';
import '../styles/common/tables.scss';
import '../styles/partials/recentlyView.scss';
import '../styles/partials/news-block.scss';
import '../styles/partials/pagenumberer.scss';
import '../styles/partials/product-categories.scss';
import '../styles/partials/product-color.scss';
import '../styles/partials/products-specials.scss';
import '../styles/partials/properties.scss';
import '../styles/partials/toolbar-bottom.scss';
import '../scripts/_partials/cart/styles/cart.scss';
import '../scripts/_partials/colors-viewer/colorsViewer.module.js';
import '../scripts/_partials/sizes-viewer/sizesViewer.module.js';
import '../scripts/_partials/shipping/shipping.module.js';
import '../scripts/_common/carousel/carousel.module.js';
import '../styles/views/checkout.scss';
import '../styles/views/checkoutSuccess.scss';
import '../styles/views/news.scss';
import '../styles/views/brands.scss';
import '../styles/views/myAccount.scss';
import '../styles/views/contacts.scss';
import '../styles/views/feedback.scss';
import '../styles/views/errors.scss';
import '../styles/views/preorder.scss';
import '../styles/views/compareproducts.scss';
import '../styles/views/wishlist.scss';
import '../styles/partials/footer.scss';
import '../styles/partials/footer-menu.scss';
import '../scripts/_vendors/product-share.js';
import '../styles/partials/banners.scss';
import '../styles/partials/bonus-card.scss';
import '../styles/partials/gift.scss';
import '../styles/partials/login.scss';
import '../styles/partials/mobileOverlap.scss';
import '../styles/partials/stickers.scss'; //import '../styles/views/bill.scss';

import '../styles/views/giftcertificate.scss';
import '../styles/views/login.scss';
import '../styles/views/managers.scss'; //import '../styles/views/printorder.scss';